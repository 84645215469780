import { Toast } from 'vant';
import router from '../router'


export default{
	isLogin(){
		const token:(string|null)=localStorage.getItem('token');
		if(token){
			return true;
		}else{
			Toast("请先登录!")
			router.replace('/phoneLogin');
			return false;			
		}
	}
}