import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/back_blue.png'
import _imports_1 from '@/assets/images/back_w.png'
import _imports_2 from '../../assets/images/content_more_w.png'


const _withScopeId = n => (_pushScopeId("data-v-9b4f40a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_REPORTTYPES = _resolveComponent("REPORTTYPES")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.classStyle)
      }, [
        (_ctx.classStyle=='backBox')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "backBtn",
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gohistory && _ctx.gohistory(...args)))
            }))
          : _createCommentVNode("", true),
        (_ctx.classStyle=='style2')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "backBtn",
              src: _imports_1,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gohistory && _ctx.gohistory(...args)))
            }))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
        (_ctx.isMore)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.doMore && _ctx.doMore(...args))),
              class: "rightMore",
              src: _imports_2
            }))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (_ctx.showMore)
      ? (_openBlock(), _createBlock(_component_REPORTTYPES, {
          key: 0,
          show: _ctx.showMore,
          pid: _ctx.pid
        }, null, 8, ["show", "pid"]))
      : _createCommentVNode("", true)
  ], 64))
}