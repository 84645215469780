<template>
	<div class="answerpinglunBox">
		<van-popup
		  v-model:show="showAnswerPinglun"
		  round
		  position="bottom"
		  :style="{ 'height': '2.6rem'}"
		  @closed="closeAnserpinglun"
		>
		<ul>
			<li @click="reportPostFun">举报</li>
		</ul>
		<div class="cancelBtn" @click="closeAnserpinglun">取消</div>
		</van-popup>
	</div>
	<!--举报分类-->
	<SELECTTYPE v-if="showReportTypes" :pid="pid" :isShow="showReportTypes"></SELECTTYPE>
</template>

<script>
	import { defineComponent } from 'vue'
	import {Popup,Toast} from 'vant';
	import request from '@/service/request';
	import common from '@/util/common';
	import SELECTTYPE from '@/components/reportTypes/selectType.vue'
	export default defineComponent({
		components: {
			[Popup.name]:Popup,
			SELECTTYPE
		},
		props:{
			show:{
				default:false,
				type:Boolean
			},
			pid:{
				type:String,
				default:''
			}
		},
		data(){
			return{
				showAnswerPinglun:true,
				showReportTypes:false
			}
		},
		created(){
			this.showAnswerPinglun=this.show;
		},
		methods:{
			closeAnserpinglun(){
				this.showAnswerPinglun=false;
				this.$parent.closeMore();
			},
			reportPostFun(){
				if(common.isLogin()){
					this.showReportTypes=true;
				}
			},
			//关闭举报
			closeReportTypes(){
				this.showReportTypes=false;
				this.closeAnserpinglun();
				
			}
		}
	});
</script>

<style lang="less">
	.answerpinglunBox{
		position:fixed;
		bottom:0;
		left:0;
		right:0;
		z-index: 1002;
	}
</style>
