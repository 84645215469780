
import { defineComponent } from 'vue';
import REPORTTYPES from '@/components/reportTypes/reportTypes.vue'

export default defineComponent({
  name: 'test',
  components: {
  	REPORTTYPES
  },
  data(){
	  return{
		  showMore:false
	  }
  },
  props: {
   title:String,
   classStyle:{
	   default:'backBox',
	   type:String
   },
   isMore:{
	   default:false,
	   type:Boolean
   },
   history:{
	   default:'',
	   type:String
   },
   backFun:{
	   default:null,
	   type:Function
   },
   tid:{
		type:String,
		default:''
   },  
   pid:{
   		type:String,
   		default:''
   }
  },
  created(){
	  console.log(this.classStyle);
	  console.log(this.title);
  },
  methods:{
	gohistory(){
		if(this.history){
			this.$router.replace(this.history)
		}else if(this.backFun){
			//this.$emit('clickfather', args);
			//this.$emit(this.backFun);
			this.backFun();
		}else
		this.$router.go(-1);
	},
	doMore(){
		this.showMore=true;
	},
	closeMore(){
		this.showMore=false
	}
  }
});
